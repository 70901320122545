import React from "react";
import { Button } from "clutch/src/Button/Button.js";

import { appURLs } from "@/app/constants.mjs";
import ConsoleCommandsMDX from "@/game-cs2-marketing/console-commands.mdx";
import Download from "@/inline-assets/download.svg";
import GameArticle from "@/shared/GameArticle.jsx";

const DownloadButton = ({ href, children }) => {
  return (
    <Button
      className="type-form--button flex-button"
      iconLeft={<Download width="18px" height="18px" />}
      emphasis="high"
      href={href}
      target="_blank"
    >
      {children}
    </Button>
  );
};

function ConsoleCommands() {
  return (
    <GameArticle
      articleHeader={{
        title: ["cs2:meta.consoleCommands.header", "CS2"],
        subTitle: [
          "cs2:meta.consoleCommands.title",
          "Best Counter Strike 2 console commands and settings",
        ],
        date: new Date("Sep 06, 2023 10:00:00"),
        imageURL: `${appURLs.CDN}/blitz/cs2/articles/console-commands.webp`,
      }}
      ContentMDX={ConsoleCommandsMDX}
      components={{ Button: DownloadButton }}
    />
  );
}

export default ConsoleCommands;

export const meta = () => ({
  title: [
    "cs2:meta.consoleCommands.title",
    "Best CS2 Console Commands and Settings",
  ],
  description: [
    "cs2:meta.consoleCommands.description",
    "Stay ahead of the game with the latest commands introduced in Counter-Strike. From game mechanics like jumpthrow to server controls, explore them all to optimize your gameplay and take full advantage of Counter-Strike's newest features.",
  ],
  subtitle: true,
  image: {
    url: `${appURLs.CDN}/blitz/cs2/articles/console-commands.webp`,
    alt: [
      "cs2:meta.consoleCommands.title",
      "Best CS2 Console Commands and Settings",
    ],
    width: 656,
    height: 369,
  },
});
