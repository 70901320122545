import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = {
    a: "a",
    br: "br",
    code: "code",
    h3: "h3",
    h4: "h4",
    img: "img",
    li: "li",
    p: "p",
    pre: "pre",
    strong: "strong",
    ul: "ul",
    ..._provideComponents(),
    ...props.components
  }, {Button, Card} = _components;
  if (!Button) _missingMdxReference("Button", true);
  if (!Card) _missingMdxReference("Card", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h3, {
      id: "how-to-enable-console",
      children: _jsx(_components.a, {
        href: "#how-to-enable-console",
        children: "How to enable console?"
      })
    }), "\n", _jsxs(Card, {
      children: [_jsxs(_components.p, {
        children: ["To enable the console in CS2, you need to launch the game, ", _jsx(_components.strong, {
          children: "go to the settings"
        }), " in the top left corner and navigate to the ", _jsx(_components.strong, {
          children: "Game tab"
        }), ".", _jsx(_components.br, {}), "\n", "You will now see a row called ", _jsx(_components.strong, {
          children: "Enable Developer Console"
        }), ". Set it to Yes.", _jsx(_components.br, {}), "\n", "By default, the ", _jsx(_components.strong, {
          children: "key shortcut is set to ~"
        }), " but you can change it by going into ", _jsx(_components.strong, {
          children: "Keyboard / Mouse tab"
        }), " and scroll to the section called ", _jsx(_components.strong, {
          children: "UI Keys"
        }), ". You will see a row called ", _jsx(_components.strong, {
          children: "Toggle Console"
        }), " and set the key shortcut you want."]
      }), _jsx(_components.p, {
        children: _jsx(_components.img, {
          src: "https://blitz-cdn.blitz.gg/blitz/cs2/articles/how-to-open-console.webp",
          alt: "How to open console"
        })
      })]
    }), "\n", _jsx(_components.h3, {
      id: "best-console-commands",
      children: _jsx(_components.a, {
        href: "#best-console-commands",
        children: "Best console commands"
      })
    }), "\n", _jsxs(Card, {
      children: [_jsxs(_components.p, {
        children: ["Here at Blitz, we have created a config file to download with the best console commands automatically executed when you launch your game.", _jsx(_components.br, {}), "\n", "See detailed commands or download the file just below:"]
      }), _jsx(_components.h4, {
        id: "netcode-settings",
        children: _jsx(_components.a, {
          href: "#netcode-settings",
          children: "Netcode settings"
        })
      }), _jsx(_components.p, {
        children: "The most optimized netcode commands to remove the feeling of lags in-game."
      }), _jsx(_components.pre, {
        children: _jsx(_components.code, {
          children: "cl_updaterate \"128\"\ncl_interp_ratio \"1\"\ncl_interp \"0.015625\" // Set to 0.03125 for less stable connection\n"
        })
      }), _jsx(_components.h4, {
        id: "fps-settings",
        children: _jsx(_components.a, {
          href: "#fps-settings",
          children: "FPS settings"
        })
      }), _jsx(_components.p, {
        children: "See your frames rate in real-time and remove the FPS limiter."
      }), _jsx(_components.pre, {
        children: _jsx(_components.code, {
          children: "fps_max \"400\"\ncq_netgraph \"1\" // Set to 0 to disable it (CSGO net_graph command)\ncl_showfps \"1\" // Set to 0 to disable it\n"
        })
      }), _jsx(_components.h4, {
        id: "jumpthrow-bind",
        children: _jsx(_components.a, {
          href: "#jumpthrow-bind",
          children: "Jumpthrow bind"
        })
      }), _jsx(_components.p, {
        children: "Just like CS:GO, equip the grenade you want, hold left-click and click the defined key to throw a perfect grenade."
      }), _jsx(_components.pre, {
        children: _jsx(_components.code, {
          children: "alias \"+boing\" \"+jump\"\nalias \"+ding\" \"-attack; -attack2\"\nalias \"+dong\" \"-jump\"\nbind \"n\" \"+boing; +ding; +dong\" // Change ‘n’ with your desired key shortcut\n"
        })
      }), _jsx(Button, {
        href: `${props.appURLs.CDN_PLAIN}/blitz/cs2/cs2blitz.cfg`,
        children: "Download .cfg file"
      }), _jsxs(_components.p, {
        children: ["Once downloaded, ", _jsx(_components.strong, {
          children: "copy and paste cs2blitz.cfg"
        }), " in this folder (default CS2 install) or right-click on CS:GO in ", _jsx(_components.strong, {
          children: "Steam library"
        }), " and select ", _jsx(_components.strong, {
          children: "Properties"
        }), " and in the ", _jsx(_components.strong, {
          children: "Local Files"
        }), " nav item click ", _jsx(_components.strong, {
          children: "Browse"
        }), " to open the installation folder:"]
      }), _jsx(_components.pre, {
        children: _jsx(_components.code, {
          children: "C:\\SteamLibrary\\steamapps\\common\\Counter-Strike Global Offensive\\game\\csgo\\cfg\n"
        })
      })]
    }), "\n", _jsx(_components.h3, {
      id: "cs2-launch-options",
      children: _jsx(_components.a, {
        href: "#cs2-launch-options",
        children: "CS2 Launch options"
      })
    }), "\n", _jsxs(Card, {
      children: [_jsx(_components.p, {
        children: "In addition to in-game commands, you can set up launch options to avoid having to manually execute console commands by doing exec cs2blitz."
      }), _jsxs(_components.p, {
        children: ["To add these options, go to ", _jsx(_components.strong, {
          children: "Settings"
        }), " from ", _jsx(_components.strong, {
          children: "Steam library"
        }), ", right-click on CS:GO and select ", _jsx(_components.strong, {
          children: "Properties"
        }), ". Scroll to the bottom of the ", _jsx(_components.strong, {
          children: "General nav"
        }), " item and in the ", _jsx(_components.strong, {
          children: "Launch option field"
        }), ", copy this:"]
      }), _jsx(_components.pre, {
        children: _jsx(_components.code, {
          children: "-novid -console -high -nod3d9ex -freq 144 +exec cs2blitz -allow_third_party_software\n"
        })
      }), _jsxs(_components.p, {
        children: [_jsx(_components.strong, {
          children: "-novid"
        }), " will remove the intro video of CS:GO. Not yet available for CS2."]
      }), _jsxs(_components.p, {
        children: [_jsx(_components.strong, {
          children: "-console"
        }), " will show console without having to enable it from in-game settings."]
      }), _jsxs(_components.p, {
        children: [_jsx(_components.strong, {
          children: "-high"
        }), " will set CS2 as having higher priority in task manager, meaning you should experience less stutters."]
      }), _jsxs(_components.p, {
        children: [_jsx(_components.strong, {
          children: "-nod3d9ex"
        }), " will disable Direct3D 9Ex to improve performance on older systems."]
      }), _jsxs(_components.p, {
        children: [_jsx(_components.strong, {
          children: "-freq <YOUR_FREQUENCY>"
        }), " will set the refresh rate of your monitor to match your monitor’s capabilities."]
      }), _jsxs(_components.p, {
        children: [_jsx(_components.strong, {
          children: "+exec cs2blitz"
        }), " will automatically execute the config file you have downloaded."]
      }), _jsxs(_components.p, {
        children: [_jsx(_components.strong, {
          children: "-allow_third_party_software"
        }), " will allow apps like Blitz to run properly in the background."]
      }), _jsx(_components.p, {
        children: _jsx(_components.img, {
          src: "https://blitz-cdn.blitz.gg/blitz/cs2/articles/launch-options.webp",
          alt: "Launch Options"
        })
      })]
    }), "\n", _jsx(_components.h3, {
      id: "best-cs2-settings",
      children: _jsx(_components.a, {
        href: "#best-cs2-settings",
        children: "Best CS2 settings"
      })
    }), "\n", _jsxs(Card, {
      children: [_jsx(_components.p, {
        children: "Here is the best CS2 settings to optimize your FPS:"
      }), _jsx(_components.p, {
        children: _jsx(_components.strong, {
          children: "Video > Advanced Video"
        })
      }), _jsxs(_components.ul, {
        children: ["\n", _jsx(_components.li, {
          children: "Boost Player Contrast set to disabled."
        }), "\n", _jsx(_components.li, {
          children: "Wait for Vertical Sync set to disabled."
        }), "\n", _jsx(_components.li, {
          children: "Multisampling Anti-Aliasing Mode set to 8X MSAA."
        }), "\n", _jsx(_components.li, {
          children: "Global Shadow Quality set to low."
        }), "\n", _jsx(_components.li, {
          children: "Model / Texture Detail set to low."
        }), "\n", _jsx(_components.li, {
          children: "Shader detail set to low."
        }), "\n", _jsx(_components.li, {
          children: "Particle Detail set to low."
        }), "\n", _jsx(_components.li, {
          children: "Ambient Occlusion set to disabled."
        }), "\n", _jsx(_components.li, {
          children: "High Dynamic Range set to quality."
        }), "\n", _jsx(_components.li, {
          children: "FidelityFX Super Resolution set to disabled."
        }), "\n", _jsx(_components.li, {
          children: "NVIDIA Reflex Low Latency set to disabled."
        }), "\n"]
      }), _jsx(_components.p, {
        children: _jsx(_components.img, {
          src: "https://blitz-cdn.blitz.gg/blitz/cs2/articles/best-settings.webp",
          alt: "Best settings"
        })
      })]
    }), "\n", _jsx(_components.h3, {
      id: "other-cs2-commands",
      children: _jsx(_components.a, {
        href: "#other-cs2-commands",
        children: "Other CS2 commands"
      })
    }), "\n", _jsxs(Card, {
      children: [_jsx(_components.p, {
        children: "Here is a list of useful commands you can use in practice mode or you can download Blitz warmup config file to execute on practice server:"
      }), _jsxs(_components.ul, {
        children: ["\n", _jsxs(_components.li, {
          children: [_jsx(_components.strong, {
            children: "sv_cheats 1"
          }), " to allow you to override some of basic game limitations and to enable some god mod commands. Don’t worry, you will not be Vac banned for using it in practice mode."]
        }), "\n", _jsxs(_components.li, {
          children: [_jsx(_components.strong, {
            children: "god"
          }), " to enable god mode and take no damage."]
        }), "\n", _jsxs(_components.li, {
          children: [_jsx(_components.strong, {
            children: "noclip"
          }), " to fly around the map."]
        }), "\n", _jsxs(_components.li, {
          children: [_jsx(_components.strong, {
            children: "sv_infinite_ammo"
          }), " 1 to give you infinite ammo and grenades."]
        }), "\n", _jsxs(_components.li, {
          children: [_jsx(_components.strong, {
            children: "mp_roundtime 60"
          }), " to set round time to 1 hour, ideal to practice grenades."]
        }), "\n", _jsxs(_components.li, {
          children: [_jsx(_components.strong, {
            children: "mp_maxmoney 60000"
          }), " to extend the money limit."]
        }), "\n", _jsxs(_components.li, {
          children: [_jsx(_components.strong, {
            children: "mp_startmoney 60000"
          }), " to start with $60,000 and buy multiple grenades or weapons."]
        }), "\n", _jsxs(_components.li, {
          children: [_jsx(_components.strong, {
            children: "mp_buytime 9"
          }), "999 to buy whenever you want."]
        }), "\n", _jsxs(_components.li, {
          children: [_jsx(_components.strong, {
            children: "mp_buy_anywhere 1"
          }), " to buy wherever you want."]
        }), "\n", _jsxs(_components.li, {
          children: [_jsx(_components.strong, {
            children: "mp_freezetime 0"
          }), " to remove the freeze time before round starts."]
        }), "\n", _jsxs(_components.li, {
          children: [_jsx(_components.strong, {
            children: "sv_grenade_trajectory 1"
          }), " to see the trajectory of your grenades."]
        }), "\n", _jsxs(_components.li, {
          children: [_jsx(_components.strong, {
            children: "sv_grenade_trajectory_prac_pipreview 1"
          }), " to see where your grenade will land."]
        }), "\n", _jsxs(_components.li, {
          children: [_jsx(_components.strong, {
            children: "mp_restartgame 1"
          }), " to restart round."]
        }), "\n", _jsxs(_components.li, {
          children: [_jsx(_components.strong, {
            children: "bot_add"
          }), " to add bots to the server."]
        }), "\n", _jsxs(_components.li, {
          children: [_jsx(_components.strong, {
            children: "bot_kick"
          }), " to remove bots to the server."]
        }), "\n", _jsxs(_components.li, {
          children: [_jsx(_components.strong, {
            children: "bot_add_t"
          }), " to add Terrorist to the server."]
        }), "\n", _jsxs(_components.li, {
          children: [_jsx(_components.strong, {
            children: "bot_add_ct"
          }), " to add Counter-Terrorist to the server."]
        }), "\n", _jsxs(_components.li, {
          children: [_jsx(_components.strong, {
            children: "bot_place"
          }), " to place a bot at the exact location where your crosshair is watching."]
        }), "\n", _jsxs(_components.li, {
          children: [_jsx(_components.strong, {
            children: "bot_stop 1"
          }), " to stop bot moving. Set to 0 to let the bots move again."]
        }), "\n", _jsxs(_components.li, {
          children: [_jsx(_components.strong, {
            children: "bot_crouch 1"
          }), " to make the bots crouch. Set to 0 to disable."]
        }), "\n"]
      }), _jsx(Button, {
        href: `${props.appURLs.CDN_PLAIN}/blitz/cs2/warmup.cfg`,
        children: "Download .cfg file"
      }), _jsxs(_components.p, {
        children: ["Once downloaded, ", _jsx(_components.strong, {
          children: "copy and paste warmup.cfg"
        }), " in this folder (default CS2 install) or right-click on CS:GO in ", _jsx(_components.strong, {
          children: "Steam library"
        }), " and select ", _jsx(_components.strong, {
          children: "Properties"
        }), " and in the ", _jsx(_components.strong, {
          children: "Local Files"
        }), " nav item click ", _jsx(_components.strong, {
          children: "Browse"
        }), " to open the installation folder:"]
      }), _jsx(_components.pre, {
        children: _jsx(_components.code, {
          children: "C:\\SteamLibrary\\steamapps\\common\\Counter-Strike Global Offensive\\game\\csgo\\cfg\n"
        })
      }), _jsx(_components.p, {
        children: "Then in the console, execute the following command:"
      }), _jsx(_components.pre, {
        children: _jsx(_components.code, {
          children: "exec warmup\n"
        })
      })]
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
